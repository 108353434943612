<template>
  <div class="ng-star-inserted">
    <h1 class="pt-3 pb-4">{{ $t('confirmCode.title') }}</h1>
    <h5 class="small text-white opacity-50 text-center">{{ $t('confirmCode.desc') }}</h5>
    <div class="form-group1">
      <!-- <div class="wrapper ng-star-inserted">
          <input class="otp-input ng-valid ng-star-inserted" type="number" v-model="code[index]" v-for="(item,index) in code" :key="index">
      </div> -->
      <input type="number" class="text-center">
      <router-link to="/sign/login" class="btn text-white mt-5">{{
          $t('common.cancel')
        }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      code: [null, null, null, null, null, null],
    }
  },
  methods: {}
}
</script>

<style scoped>
.form-group1 .wrapper {
  display: flex;
  justify-content: space-between;
}

.wrapper input {
  width: 30px;
  height: 30px;
  padding: 0;
  text-align: center;
  background-color: #fff;
  color: #fff;
}
</style>