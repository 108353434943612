<template>
  <div class="unOuter mainOuter">
    <div class="container_un subOTP animated fadeInLeft ani_delay05">
      <div class="subWhite">
        <div class="sub_bg_bot"></div>
        <div class="subTitBox">
          <p id="t_page_title" class="subTitle">{{ $t('otp.title') }}</p>
        </div>
        <div class="RightBox">
          <div class="qrWrap">
            <p id="" class="otp">
              <img src="@/assets/img/security.png">
            </p>
            <button id="t_btn_remove" class="btnCopy1 card_bg5" v-b-modal.modal-1
                    v-if="otp_able=='N'"> {{ $t('otp.setting') }}
            </button>
            <div id="is_auth" class='text-white' v-if="otp_able=='Y'">{{ $t('otp.complete') }}</div>
          </div>
          <div class="btnWrapG gap-5">
            <a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="new">
              <p class="btnGoogle">{{ $t('common.googlePlay') }}</p>
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&amp;hl=en_IN&amp;gl=US"
               target="new">
              <p class="btnApp">{{ $t('common.appleStore') }}</p>
            </a>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="modal-1" :title="this.$t('otp.modalTitle')" hide-footer>
      <div class="ng-star-inserted">
        <h3 style="text-align: center; font-size: 18px; color: #666;">{{ $t('otp.modalDesc') }}</h3>
        <div
            class="ng-untouched ng-pristine ng-invalid text-center">
          <div class="input-group">
            <input type="text" class="form-control text-black ng-untouched ng-pristine ng-invalid"
                   v-model="otp" readonly style="height: 50px; margin: auto; display: block;">
            <button type="button" class="btn btn-sm mb-0" v-clipboard:copy="otp"
                    v-clipboard:success="onCopy">
              <i class="fa fa-copy"></i>
            </button>
          </div>
          <p>{{ $t('otp.or') }}</p>
          <vue-qr :text="url" :size="200"></vue-qr>
          <div class="form-group mt-3 mb-3" style="text-align: center;">
            <input type="text" pattern="[0-9]*" formcontrolname="set2fa"
                   class="form-control ng-untouched ng-pristine ng-invalid"
                   style="height: 50px; margin: auto; display: block;" ng-reflect-pattern="[0-9]*"
                   ng-reflect-name="set2fa" :placeholder="$t('otp.placeholder')" v-model="otp_code">
          </div>
          <div class="form-group">
            <button class="btn btn-primary btnCopy1 border-0" style="width: 250px; height: 55px;"
                    @click="otpInsert()">{{ $t('otp.setOtp') }}
            </button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>

const CryptoJS = require("crypto-js");
import VueQr from 'vue-qr'

export default {
  components: {VueQr},
  data() {
    return {
      otp: '',
      url: '123',
      otp_able: '',
      otp_code: '',
    }
  },
  mounted() {
    this.CheckOTP();
    this.GenerationOTP()
  },
  methods: {
    CheckOTP() {
      this.$axios.post('/member/profile/CHECK_OTP', {}).then(
          res => {
            if (res.status == 200) {
              if (res.data.code == '200') {
                const e_body = res.data.body;
                const bytes = CryptoJS.AES.decrypt(e_body, process.env.VUE_APP_SECRET_TOKEN);
                const d_res = bytes.toString(CryptoJS.enc.Utf8);
                const body = JSON.parse(d_res)
                this.otp_able = body.otp_able;

              } else if (res.data.code == '9999') {
                this.$store.dispatch('SETLOGOUT').then(
                    () => {
                      window.location.href = "/sign/login";
                    }
                )
              }
            }
          }
      )
    },
    GenerationOTP: function () {
      this.$axios.post('/member/profile/CREATE_OTP', {}).then(
          res => {
            if (res.status == 200) {
              if (res.data.code == '200') {
                this.loading = false;
                const e_body = res.data.body;
                const bytes = CryptoJS.AES.decrypt(e_body, process.env.VUE_APP_SECRET_TOKEN);
                const d_res = bytes.toString(CryptoJS.enc.Utf8);
                const body = JSON.parse(d_res)

                this.otp = body.otp;
                this.url = body.url;
              } else if (res.data.code == '9999') {
                this.$store.dispatch('SETLOGOUT').then(
                    () => {
                      window.location.href = "/sign/login";
                    }
                )
              }
            }
          }
      )
    },
    onCopy(e) {
      this.$alert(`${this.$i18n.t('common.copied')}\n${e.text}`);
    },
    otpInsert() {
      const otp = this.otp;
      const otp_code = this.otp_code;

      const body = {otp, otp_code};

      const req = CryptoJS.AES.encrypt(JSON.stringify(body),
          process.env.VUE_APP_SECRET_TOKEN).toString();
      this.$axios.post('/member/profile/OTP_INSERT', {req}).then(
          res => {
            if (res.status == 200) {
              if (res.data.code == '200') {
                this.$alert(this.$i18n.t('otp.ok')).then(
                    () => {
                      this.$router.go()
                    }
                )

              } else if (res.data.code == '100') {
                this.$alert(this.$i18n.t('otp.retry')).then(
                    () => {

                    }
                )
              } else if (res.data.code == '9999') {
                this.$store.dispatch('SETLOGOUT').then(
                    () => {
                      window.location.href = "/sign/login";
                    }
                )
              }
            }
          }
      )
    },
  }

}
</script>
